.contenedor-imagen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.imagen {
    height: 100px;
    margin: 0 auto;
    position: relative;
    transition: transform 0.5s;
}

.sombra {
    width: 100px;
    height: 20px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.5s, width 0.5s, background 0.5s;
}